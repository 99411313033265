import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { routingPathLogin, routingPathNewDomainRedirect } from '../../shared/constants/routing-path.constant';
import { ApiResourceAccountService } from '../../shared/modules/api/services/api-resource-account/api-resource-account.service';
import { UrlsService } from '../../shared/services/urls.service';
import { UserSessionService } from '../../shared/services/user-session.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private userSession: UserSessionService,
    private urlsService: UrlsService,
    private router: Router,
    private apiResourceAccountService: ApiResourceAccountService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token: string | null = this.userSession.getToken();

    request = request.clone({
      setHeaders: {
        Authorization: token
          ? `Token ${this.userSession.getToken()}`
          : '',
      },
    });

    return next.handle(request).pipe(
      // @TODO refactor to get rid of ignore
      // @ts-ignore
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (this.urlsService.isProductionUrl()) {
            this.router.navigateByUrl(routingPathNewDomainRedirect);
          } else if (error.status === 401 || error.status === 403) {
            if (request.url.includes('/api/logout/')) {
              this.userSession.removeToken();
              this.router.navigateByUrl(routingPathLogin);
            } else {
              this.apiResourceAccountService.logout$().subscribe({
                next: () => {
                  this.removeTokenAndRedirectToLogin();
                },
                error: () => {
                  this.removeTokenAndRedirectToLogin();
                },
              });
            }
          }

          return throwError(() => error);
        }
      }),
    );
  }

  private removeTokenAndRedirectToLogin(): void {
    this.userSession.removeToken();
    this.router.navigateByUrl(routingPathLogin);
  }
}
