import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, first, firstValueFrom, iif, map, mergeMap, of, tap } from 'rxjs';
import { AvailableViewsStore } from '../../../../../store/available-views/available-views.store';
import { CurrentViewStore } from '../../../../../store/current-view/current.view.store';
import { DeviceShortInterface } from '../../../../interfaces/device.interface';
import { ViewInterface } from '../../../../interfaces/view.interface';
import { ApiResourceAvailableViewsService } from '../../../../modules/api/services/api-resource-available-views/api-resource-available-views.service';
import { UrlsService } from '../../../../services/urls.service';

export interface GetUrlInterface {
  device: DeviceShortInterface;
  url: string;
  isViewAvailable: boolean;
};


@Injectable()
export class DevicesDropdownNavigationService {
  constructor(
    private router: Router,
    private currentViewStore: CurrentViewStore,
    private availableViewsStore: AvailableViewsStore,
    private apiResourceAvailableViewsService: ApiResourceAvailableViewsService,
    private urlsService: UrlsService,
  ) {}

  async navigateToRobot(data: GetUrlInterface): Promise<void> {
    if (data.isViewAvailable) {
      const rrs = this.router.routeReuseStrategy;
      const prev = rrs.shouldReuseRoute;
      rrs.shouldReuseRoute = () => false;
      await this.router.navigateByUrl(data.url).catch(() => {});
      rrs.shouldReuseRoute = prev;
    } else {
      this.router.navigateByUrl(data.url);
    }
  }

  async getUrl(device: DeviceShortInterface): Promise<GetUrlInterface> {
    const currentView = this.currentViewStore.getCurrentView();
    const isViewAvailable: boolean = !!currentView &&
      await this.isCurrentViewAvailableInNextDevice(currentView, device);

    const url: string = (isViewAvailable && currentView) ?
      currentView.base_url + device.id :     // reload
      this.urlsService.getRoutePath(device); // navigate to robot root page

    return { device, url, isViewAvailable };
  }

  async isCurrentViewAvailableInNextDevice(
    currentView: ViewInterface,
    nextDevice: DeviceShortInterface,
  ): Promise<boolean> {
    const availableViews$ = this.getNextDeviceAvailableViews$(nextDevice.id);
    const availableViews = await firstValueFrom(availableViews$);
    return availableViews.some(nextView => nextView.base_url === currentView.base_url);
  }

  private getNextDeviceAvailableViews$(nextDeviceId: string): Observable<ViewInterface[]> {
    return this.availableViewsStore.getAvailableViews$(nextDeviceId).pipe(
      first(),
      mergeMap((availableViews: ViewInterface[]) => iif(
        () => !!availableViews,
        of(availableViews).pipe(
          map((av: ViewInterface[] | undefined) => av || []),
        ),
        this.apiResourceAvailableViewsService.getAvailableViews$(nextDeviceId).pipe(
          tap((apiAvailableViews: ViewInterface[]) => {
            this.availableViewsStore.updateAvailableViews(nextDeviceId, apiAvailableViews);
          }),
        ),
      )),
    );
  }
}
