import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PasswordChangeInterface } from '../../../../shared/interfaces/password-change.interface';
import { FormGroupGenericType } from '../../../../shared/modules/form/types/form-group-generic.type';
import { AdminOverallPasswordService } from './admin-overall-password.service';

@Component({
  selector: 'app-admin-overall-password',
  templateUrl: './admin-overall-password.component.html',
  styleUrls: ['./admin-overall-password.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
})
export class AdminOverallPasswordComponent implements OnInit {
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();

  formGroup: FormGroupGenericType<PasswordChangeInterface> = this.adminOverallPasswordService.getFormGroup();

  private readonly destroyed$: Subject<unknown> = new Subject();

  constructor(
    private adminOverallPasswordService: AdminOverallPasswordService,
  ) {}

  ngOnInit(): void {
    this.formGroup.valueChanges.pipe(
      takeUntil(this.destroyed$),
    ).subscribe(() => {
      this.valid.emit(this.formGroup.valid);
    });
  }
}
