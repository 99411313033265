<div class="account-page">
  <div class="kgn-container kgn-grid">
    <div class="account-page__form">
      <div class="account-page__form-header kgn-grid kgn-gap-2">
        <a href="https://kogena.com" class="account-page-logo"></a>
        <div class="desc">Remote <span class="red">diagnostics <br>and maintenance Platform</span><br> for industrial and collaborative robots</div>
      </div>
      <div class="account-container__content__panel__text">Enter your credentials to access Kogena Platform</div>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login-form">
        <div class="login-form__form-group">
          <app-form-input
            [formControl]="loginForm.controls['username']"
            [placeholder]="'Login'"
            type="email"
            browserAutocomplete="email"
          ></app-form-input>
        </div>
        <div class="login-form__form-group">
          <app-form-input
            [formControl]="loginForm.controls['password']"
            [placeholder]="'Password'"
            type="password"
            browserAutocomplete="current-password"
          ></app-form-input>
        </div>

        <span
          *ngIf="loginForm.hasError('incorrectCredentials')"
          class="login-form__form-group__feedback"
        >
          Unable to log in with provided credentials
        </span>

        <button
          type="submit"
          class="login-form__submit"
          matRipple
        >
          Log in
        </button>
        <div class="login-form__subtext">
          <span>By clicking login button You accept</span>&nbsp;
          <a [href]="termsConditionURL" target="_blank">Terms & Conditions</a>
        </div>
        <div class="login-form__subtext">
          <span>Forgot your password?</span>&nbsp;
          <a (click)="goToResetPassword()">Regain access</a>
        </div>
      </form>
    </div>
  </div>
  <div class="account-page-bg"></div>
</div>
