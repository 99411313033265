<div class="admin-overall-modal">
  <mat-dialog-content>
    <mat-tab-group (selectedIndexChange)="tabIndexChange($event)">
      <mat-tab label="Credentials">
        <div class="admin-overall-modal__content">
          <app-admin-overall-password (valid)="passwordValid($event)"></app-admin-overall-password>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="true">Close</button>
    <button (click)="save()" [disabled]="!isSaveAvailable" mat-button>Save</button>
  </mat-dialog-actions>
</div>
