import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { SUPPORT_EMAIL } from '../../../shared/constants/support-email.const';

@Component({
  selector: 'app-account-redirect-page',
  templateUrl: './account-redirect-page.component.html',
  styleUrls: ['./account-redirect-page.component.scss', '../account-page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatButton, MatIcon],
})
export class AccountRedirectPageComponent {
  readonly supportEmail = SUPPORT_EMAIL;

  private readonly newDomainURL = 'https://app.kogena.com';

  redirectToNewDomain(): void {
    window.location.replace(this.newDomainURL);
  }
}
