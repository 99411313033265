import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { PasswordChangeInterface } from '../../../../shared/interfaces/password-change.interface';
import { apiUrlChangePassword } from '../../../../shared/modules/api/constants/api-url.constant';
import { FormGroupGenericType } from '../../../../shared/modules/form/types/form-group-generic.type';

@Injectable({
  providedIn: 'root',
})
export class AdminOverallPasswordService {
  private readonly formGroup: FormGroupGenericType<PasswordChangeInterface>;
  private readonly passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

  constructor(
    private httpClientService: HttpClient,
  ) {
    this.formGroup = this.getFormGroupDefinition();
  }

  getFormGroup(): FormGroupGenericType<PasswordChangeInterface> {
    return this.formGroup;
  }

  savePassword$(): Observable<any> {
    return this.httpClientService.put(apiUrlChangePassword, this.formGroup.value);
  }

  private getFormGroupDefinition(): FormGroupGenericType<PasswordChangeInterface> {
    return new FormGroup({
      current_password: new FormControl<string | null>(null, [
        Validators.required,
      ]),
      password: new FormControl<string | null>(null, [
        Validators.required,
        Validators.pattern(this.passwordRegex),
      ]),
      confirm: new FormControl<string | null>(null, [
        Validators.required,
        Validators.pattern(this.passwordRegex),
      ]),
    });
  }
}
