import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { AdminOverallModalComponent } from './admin-overall-modal/admin-overall-modal.component';

@Component({
  selector: 'app-admin-overall',
  templateUrl: './admin-overall.component.html',
  styleUrls: ['./admin-overall.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon],
})
export class AdminOverallComponent implements OnInit {
  constructor(
    private matDialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {}

  openAdminModal(): void {
    this.matDialog.open(AdminOverallModalComponent, {
      width: '768px',
      maxWidth: '90vw',
      height: '640px',
      maxHeight: '90vh',
    });
    this.changeDetectorRef.detectChanges();
  }
}
