import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { routingPathResetPassword } from '../../../shared/constants/routing-path.constant';
import { ApiAccountLoginInterface } from '../../../shared/modules/api/interfaces/api-account/api-account-login.interface';
import { ApiResourceAccountService } from '../../../shared/modules/api/services/api-resource-account/api-resource-account.service';
import { FormInputComponent } from '../../../shared/modules/form/components/form-input/form-input.component';
import { FormGroupGenericType } from '../../../shared/modules/form/types/form-group-generic.type';
import { GTMService } from '../../../shared/services/gtm.service';
import { UserSessionService } from '../../../shared/services/user-session.service';

@Component({
  standalone: true,
  selector: 'app-account-login-page',
  templateUrl: './account-login-page.component.html',
  styleUrls: ['../account-page.scss'],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormInputComponent,
    MatRippleModule,
  ],
})
export class AccountLoginPageComponent implements OnInit {
  loginForm: FormGroupGenericType<{
    username: string;
    password: string;
  }>;
  errors = [];
  termsConditionURL = '/static/terms_of_service.pdf';

  constructor(
    private fb: FormBuilder,
    private userSessionService: UserSessionService,
    private router: Router,
    private trackingService: GTMService,
    private apiResourceAccountService: ApiResourceAccountService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: this.fb.control('', [
        Validators.required,
        Validators.email,
      ]),
      password: ['', Validators.required],
    } );
  }

  onSubmit(): void {
    if (this.loginForm.valid) {
      this.apiResourceAccountService.login$(this.loginForm.value).subscribe(
        {
          next: (r: ApiAccountLoginInterface) => {
            this.userSessionService.setToken(r.token);
            this.userSessionService.setUserName(r.user_name);
            this.trackingService.track({
              event: 'login',
              userId: r.user_name,
            });
            this.router.navigateByUrl('/');
          },
          error: (err) => {
            if (err?.status === 400) {
              this.loginForm.setErrors({ incorrectCredentials : true });
              this.changeDetectorRef.detectChanges();
            }
          },
        },
      );
    }
  }

  goToResetPassword(): void {
    this.router.navigateByUrl(routingPathResetPassword);
  }
}
