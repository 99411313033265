import { MatButtonModule } from '@angular/material/button';
import { Component } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
  standalone: true,
  selector: 'app-footer-modal-privacy-policy',
  templateUrl: './footer-modal-privacy-policy.component.html',
  styleUrls: ['./footer-modal-privacy-policy.component.scss'],
  imports: [
    MatDialogModule,
    MatButtonModule,
  ],
})
export class FooterModalPrivacyPolicyComponent {
  constructor(public dialogRef: MatDialogRef<FooterModalPrivacyPolicyComponent>) {}

  closeClick() {
    this.dialogRef.close();
  }
}
