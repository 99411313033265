import { ErrorHandler, Injectable } from '@angular/core';
import { getCurrentScope } from '@sentry/angular';
import { ENVIRONMENT } from '../../../environments/environment';
import { UserSessionService } from '../../shared/services/user-session.service';

@Injectable()
export class GlobalErrorHandlerService extends ErrorHandler {
  constructor(
    private userSessionService: UserSessionService,
  ) {
    super();
  }

  handleError(error: any): void {
    const userName = this.userSessionService.getUserName();
    const scope = getCurrentScope();
    scope.setTag('error', error?.message ?? `Unknown error: ${JSON.stringify(error)}`);
    if (userName) {
      scope.setUser({
        id: userName,
      });
    }
    scope.setExtra('stack', error?.stack);
    scope.setExtra('source', '[GlobalErrorHandler]');
    scope.setExtra('env', ENVIRONMENT.envName);

    super.handleError(error);
  }
}
