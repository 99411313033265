import { MatButtonModule } from '@angular/material/button';
import { Component } from '@angular/core';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';

@Component({
  standalone: true,
  selector: 'app-footer-modal-european-union',
  templateUrl: './footer-modal-european-union.component.html',
  styleUrls: ['./footer-modal-european-union.component.scss'],
  imports: [
    MatDialogModule,
    MatButtonModule,
  ],
})
export class FooterModalEuropeanUnionComponent {
  constructor(public dialogRef: MatDialogRef<FooterModalEuropeanUnionComponent>) {}

  closeClick(): void {
    this.dialogRef.close();
  }
}
