import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { SharedModule } from '../../../shared/shared.module';
import { AdminOverallModalComponent } from './admin-overall-modal/admin-overall-modal.component';
import { AdminOverallPasswordComponent } from './admin-overall-password/admin-overall-password.component';
import { AdminOverallComponent } from './admin-overall.component';

@NgModule({
  exports: [
    AdminOverallComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    SharedModule,
    AdminOverallComponent,
    AdminOverallModalComponent,
    AdminOverallPasswordComponent,
  ],
})
export class AdminOverallModule {}
