import { MatButtonModule } from '@angular/material/button';
import { Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  standalone: true,
  selector: 'app-footer-modal-contact',
  templateUrl: './footer-modal-contact.component.html',
  styleUrls: ['./footer-modal-contact.component.scss'],
  imports: [
    MatDialogModule,
    MatButtonModule,
  ],
})
export class FooterModalContactComponent {
}
