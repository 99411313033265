<footer class="kgn-box-shadow">
	<div class="kgn-container">
		<nav>
			<a (click)="openContactDialog($event)">Contact</a>
			<a (click)="openPrivacyPolicyDialog($event)">Privacy Policy</a>
			<a (click)="openEuropeanFundsDialog($event)">European Union project</a>
		</nav>
		<div>
			&copy; KOGENA {{ currentYear }}
		</div>
	</div>
</footer>
