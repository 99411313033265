<div class="modal">
  <mat-dialog-content>
    <div class="european-union">
      <div class="european-union__img-wrapper">
        <img src="assets/gfx/european_union.png" alt="european union funds" />
      </div>
      <article class="european-union__text">
        The project is co-financed by the European Union from the European Regional Development Fund and the National Center for Research
        and Development under the Intelligent Development Program.
      </article>
    </div>
  </mat-dialog-content>
  <div class="modal__footer">
    <mat-dialog-actions align="end">
      <button
        mat-button
        mat-dialog-close
        class="button"
      >
        Close
      </button>
    </mat-dialog-actions>
  </div>
</div>
